import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Image from "@ui/image";
import { useStaticQuery, graphql } from "gatsby";
import BoxIcon from "@components/box-icon/layout-04";
import Anchor from "@ui/anchor";
import { SectionWrap } from "./style";

const ContactArea = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "mario.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      site {
        siteMetadata {
          contact {
            phone
            email
          }
        }
      }
    }
  `);
  const { phone, email } = data.site.siteMetadata.contact;
  return (
    <SectionWrap>
      <Container>
        <Row alignItems="center">
          <Col lg={7} mb={["43px", null, null, 0]}>
            <Image src={data.file} alt="Mario" />
          </Col>
          <Col
            lg={4}
            ml="auto"
            textAlign="center"
            mb={[null, null, null, "50px"]}
          >
            <Heading color="primary">Contáctanos</Heading>
            <Text color="primary" fontSize="18px">
              ¡Llámenos o utilice el siguiente formulario y nos pondremos en
              contacto en breve!
            </Text>
            <>
              <Anchor display="block" path={`tel:${phone}`}>
                <BoxIcon
                  alignItems="center"
                  textAlign="left"
                  mt={["36px", null, null, "55px"]}
                  layout={3}
                  icon="fal fa-phone"
                  heading="¡Estamos ansiosos por conectarnos contigo!"
                  title={phone}
                />
              </Anchor>
              <Anchor display="block" path={`mailto:${email}`}>
                <BoxIcon
                  alignItems="center"
                  textAlign="left"
                  mt={["36px", null, null, "55px"]}
                  layout={3}
                  icon="fal fa-envelope"
                  heading="Envíanos un correo"
                  title={email}
                />
              </Anchor>
            </>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default ContactArea;
