import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import BoxIcon from "@components/box-icon/layout-04";
import Text from "@ui/text";
import SectionTitle from "@ui/section-title";
import { HeadingType, ItemType } from "@utils/types";
import { SolutionsWrapper, SolutionBox, SolutionBoxItem } from "./style";

const SolutionArea = () => {
  const items = [
    { id: 1, title: "Grupos electrógenos" },
    { id: 2, title: "Pista de baile LED" },
    { id: 3, title: "Pantallas LED" },
    { id: 4, title: "Hora Loca" },
    { id: 5, title: "Staff de mozos" },
  ];
  return (
    <SolutionsWrapper>
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="Servicios"
              subtitle="Servicio, calidad y estilo. Es lo que hacemos y quienes somos."
            />
          </Col>
        </Row>
        <Row mt="30px" mb="20px">
          <Col lg={12}>
            <SolutionBox>
              {items.map((solution) => (
                <SolutionBoxItem key={solution.id}>
                  <BoxIcon
                    icon="fa fa-check"
                    title={solution.title}
                    layout={3}
                  />
                </SolutionBoxItem>
              ))}
            </SolutionBox>
          </Col>
        </Row>
        <Row mt="30px" mb="20px">
          <Col>
            <Text>
              Hacemos todo lo posible para mantener esa relación cercana y
              cómoda con nuevos clientes, así como para mantener la misma
              satisfacción del cliente que servimos y aún servimos a algunos de
              los mismos clientes durante todos estos años. Clientes que
              convirtieron esta pequeña empresa de en una empresa de alquiler de
              fiestas totalmente equipada.
            </Text>
            <Text>
              Si deseas levantar el teléfono y hablar con un experto en eventos
              y hacer tu pedido por teléfono sin un contestador automático y
              hablar con alguien directa y rápidamente, tienes esa opción. Si
              deses enviar tu pedido por correo electrónico y no tienes tiempo
              para hablar, también tienes esa opción. Si deseas un cóctel íntimo
              y acogedor en casa, o una fiesta masiva de 500 en un espacio al
              aire libre, o una boda espectacular en cualquier lugar de primer
              nivel. DK Catering & Eventos estamos aquí.
            </Text>
          </Col>
        </Row>
      </Container>
    </SolutionsWrapper>
  );
};

SolutionArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
  }),
};

export default SolutionArea;
