import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@ui/page-header/layout-02";
import SolutionArea from "@containers/servicios/solution-area";
import CtaArea from "@containers/cta/layout-01";
import ContactArea from "@containers/servicios/layout-06";

const ServiciosPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Servicios" />
      <Header transparent />
      <main className="site-wrapper-reveal">
        <PageHeader title="Servicios" />
        <SolutionArea />
        <CtaArea />
        <ContactArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default ServiciosPage;
