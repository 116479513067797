import styled, { device, themeGet } from "@styled";

export const SectionWrap = styled.div`
  position: relative;
  isolation: isolate;
  padding-block: 100px;
  ${device.large} {
    padding-block: 150px;
  }
  p {
    font-weight: 600;
    color: ${themeGet("colors.royal")};
  }
`;

export const StyledBG = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
  & > div {
    width: 100%;
    height: 100%;
  }
`;
