import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "@ui/wrapper";
import Text from "@ui/text";
import { SectionWrap, StyledBG } from "./style";

const CtaArea = ({ data }) => {
  return (
    <SectionWrap>
      <StyledBG>
        <StaticImage
          src="../../../assets/images/bg/home-infotechno-video-intro-bg.jpg"
          alt="Cta BG"
        />
      </StyledBG>
      <Container>
        <Row>
          <Col lg={6} md={10} xs={11} ml="auto">
            <Text>
              Un compromiso único con la creatividad, un nivel quirúrgico de
              precisión logística y una conciencia intuitiva que ha sido
              descrita como "extrasensorial". Estos son los ingredientes
              principales que hacen que cada evento de DK Catering & Eventos de
              Mario Rivera sea un éxito absoluto.
            </Text>
            <Text>
              Desde estrategias generales hasta el más mínimo detalle, Mario
              Rivera brinda personalmente a cada cliente un nivel de atención
              incomparable y una experiencia totalmente personalizada de
              principio a fin. DK Catering & Eventos es nada menos que
              implacable cuando se trata de ofrecer ocasiones emocionantes
              perfectamente calibradas, profundamente personales para una amplia
              gama de clientes preciados.
            </Text>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default CtaArea;
